import React from 'react';

import Card from 'components/Shared/Card/Card';
import { fontSize } from 'styles/cp.js';

export interface Props {
  image?: string;
  title: string | React.ReactElement;
  children: React.ReactNode | string | null;
}

const InvoicePreviewWrapper: React.FC<Props> = ({ image, title, children }) => {
  return (
    <div className="flex" data-cy="PreviewCard_root">
      <Card padding="16px 20px 20px">
        <div className="image-title-container card-sides">
          {image && (
            <div data-cy="invoice-summary_company-logo">
              <img className="image" src={image} />
            </div>
          )}
          <span className="title" data-cy="invoice-summary_company-name">
            {title}
          </span>
        </div>
        {children}

        <style jsx>{`
          .card-sides {
            padding: 0 0 0 0;
          }
          .image-title-container {
            font-size: ${image ? fontSize.sm : fontSize.ms};

            .image {
              max-height: 60px;
              max-width: 100%;
            }

            .title {
              font-family: AvenirNextforINTUIT-Demi;
            }
          }
        `}</style>
      </Card>
    </div>
  );
};

export default InvoicePreviewWrapper;
