import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import InvoiceInfo from 'components/Core/Invoice/Info/InvoiceInfo';
import MobileInvoicePreviewWrapper from 'components/Core/Invoice/Preview/MobileInvoicePreviewWrapper/MobileInvoicePreviewWrapper';
import ViewInvoiceMessage from 'components/Core/Invoice/ViewInvoiceMessage/ViewInvoiceMessage';
import PaymentInfo from 'components/Core/Payment/PaymentInfo/PaymentInfo';
import Button from 'components/Shared/Button/Button';
import Hr from 'components/Shared/Hr/Hr';
import MerchantMoreInfo from 'components/Shared/Merchant/MoreInfo/MerchantMoreInfo';
import ProgressButton from 'components/Shared/ProgressButton/ProgressButton';
import Separator from 'components/Shared/Separator/Separator';
import { LineItems } from 'pages/subscription.page';
import SegmentIO from 'reporting/SegmentIO';
import { fetchPDFDocument } from 'store/invoiceDocument/slice';
import { breakpoints, fontSize, colors } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';
import { InvoiceDocument } from 'types/InvoiceDocument';
import { TXN_MAP } from 'types/constants';

const ContactInfoModal = (props: Props) => {
  return (
    <div className="w">
      <style jsx>{`
        .w {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          flex-direction: column;
        }

        .w-i {
          width: 320px;
          height: auto;
          background-color: ${colors.white};
          padding: 30px 20px;
          text-align: center;
        }

        .t {
          display: block;
          width: 100%;
          text-align: left;
          font-size: ${fontSize.sm};
          font-family: AvenirNextforINTUIT-Bold;
          font-weight: bold;
        }
      `}</style>
      <div className="w-i">
        <span className="t">
          <FormattedMessage id="SETTINGS_CONTACT_INFO" defaultMessage="Contact info" />
        </span>
        <Hr />
        <MerchantMoreInfo
          companyAddress={props.companyAddress}
          companyEmail={props.companyEmail}
          companyPhone={props.companyPhone}
          companyWebAddr={props.companyWebAddr}
          number={props.number}
        />
        <Separator height={25} />
        <Button onClick={props.hide} width="125px" data-cy="invoiceMoreDetails-contact-info-close">
          <FormattedMessage id="SETTINGS_CLOSE" defaultMessage="Close" />
        </Button>
      </div>
    </div>
  );
};

export type Props = {
  hide?: () => void;
  companyEmail?: string;
  companyPhone?: string;
  companyWebAddr?: string;
  companyAddress?: Record<string, string>;
  number: string;
  dueDate?: string;
  currency?: string;
  amount: number;
  balanceAmount?: number;
  isPartiallyPaid?: boolean;
  showModal: (...args: any[]) => any;
  fetchPDFStatus?: InvoiceDocument['fetchPDFStatus'];
  pdfUrl?: string;
  lazyFetch?: boolean;
  token?: string;
  totalAmount?: number;
  featureFlags?: FeatureFlags;
  fetchPDFDocument: Function;
  feeAmount?: number;
  lineItems?: LineItems;
  taxAmount?: number;
  achOnlineConvenienceFeeAmount?: number;
  achOnlineConvenienceFeeAmountPaid?: number;
};

type InvoiceMoreDetailsState = {
  invoiceViewClicked: boolean;
  isOpen: boolean;
};

class InvoiceMoreDetails extends Component<Props, InvoiceMoreDetailsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      invoiceViewClicked: false,
    };
    const methodsToBind = ['onShowContactInfoClick'];
    // @ts-ignore
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
  }
  onShowContactInfoClick() {
    this.props.showModal({
      component: ContactInfoModal,
      componentProps: {
        companyEmail: this.props.companyEmail,
        companyPhone: this.props.companyPhone,
        companyWebAddr: this.props.companyWebAddr,
        companyAddress: this.props.companyAddress,
        number: this.props.number,
        showCloseButton: false,
      },
    });
    SegmentIO.clickContactInformation();
  }
  render() {
    const {
      number,
      dueDate,
      balanceAmount,
      currency,
      isPartiallyPaid = false,
      achOnlineConvenienceFeeAmount,
      achOnlineConvenienceFeeAmountPaid,
      totalAmount,
      fetchPDFStatus = TXN_MAP.STATUS.IN_PROGRESS,
      pdfUrl,
      token,
      lazyFetch = false,
      featureFlags,
      lineItems,
      taxAmount,
      amount,
    } = this.props;
    const notFetched = this.props.fetchPDFStatus === null && this.props.lazyFetch;
    const lazyFetchPDF = () => {
      //If we're on lazyFetch test and the PDF wasn't fetched before
      if (notFetched) {
        return this.props.fetchPDFDocument(token);
      }
      return true;
    };
    const onClickView = () => {
      lazyFetchPDF();
      this.setState({ invoiceViewClicked: true });
      SegmentIO.clickViewInvoiceEvent(lazyFetch);
    };
    const fetchPDFSuccessful = fetchPDFStatus === TXN_MAP.STATUS.SUCCESS;
    if (fetchPDFSuccessful && this.state.invoiceViewClicked) {
      window.open(pdfUrl, '_self', 'noopener,nofollow');
    }
    return (
      <Fragment>
        <style jsx>{`
          .HR-margin {
            @media (max-width: ${breakpoints.md}) {
              max-width: 400px;
              margin: 10px auto;
            }

            @media (max-width: ${breakpoints.sm}) {
              max-width: 576px;
            }
          }

          .cta-w {
            padding: 0;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            max-width: 266px;
            margin: 20px auto;
          }
        `}</style>
        <MobileInvoicePreviewWrapper>
          <InvoiceInfo
            useRedesign={false}
            featureFlags={featureFlags}
            invoiceNumber={number}
            invoiceDueDate={dueDate}
            invoiceAmount={amount}
            currency={currency}
            isPartiallyPaid={isPartiallyPaid}
            achOnlineConvenienceFeeAmount={achOnlineConvenienceFeeAmount}
            achOnlineConvenienceFeeAmountPaid={achOnlineConvenienceFeeAmountPaid}
            invoiceLineItems={lineItems}
            invoiceTaxAmount={taxAmount}
          />
          <div className="HR-margin">
            <Hr
              marginTop={0}
              marginBottom={11}
              opacity={1}
              borderColor={colors.gray06}
              borderThickness={1}
            />
          </div>
          <PaymentInfo
            balanceAmount={balanceAmount}
            amount={totalAmount}
            currency={currency}
            beforePay={true}
            CFTooltip={true}
          />
          <div className="flex cta-w flex-column">
            <ProgressButton
              size="standard"
              width="108%"
              data-cy="invoiceMoreDetails-view-invoice"
              spinner={
                fetchPDFStatus === TXN_MAP.STATUS.IN_PROGRESS && this.state.invoiceViewClicked
              }
              disabled={!fetchPDFSuccessful && this.state.invoiceViewClicked}
              onClick={onClickView}
            >
              <ViewInvoiceMessage
                // @ts-ignore
                fetchPDFStatus={fetchPDFStatus}
                userClicked={this.state.invoiceViewClicked}
              />
            </ProgressButton>
          </div>
          <div className="flex cta-w flex-column">
            <Button
              size="standard"
              buttonType="tertiary"
              width="100%"
              onClick={this.onShowContactInfoClick}
              data-cy="invoiceMoreDetails-contact-info"
            >
              <FormattedMessage id="SETTINGS_CONTACT_INFO" defaultMessage="Contact info" />
            </Button>
          </div>
        </MobileInvoicePreviewWrapper>
      </Fragment>
    );
  }
}

export default connect(() => ({}), { fetchPDFDocument })(InvoiceMoreDetails);
