import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { colors, fontSize } from 'styles/cp';

export interface Props {}

const ConfirmationEmailMsg: React.FC<Props> = () => {
  return (
    <Fragment>
      <span className="payment-confirmation-email-msg">
        <FormattedMessage
          id="PAYNOW_PAYMENT_CONFIRMATION_EMAIL_SENT"
          defaultMessage="We sent you and your merchant a confirmation email"
        />
      </span>

      <style jsx>{`
        .payment-confirmation-email-msg {
          font-family: AvenirNextforINTUIT-Regular;
          font-size: ${fontSize.sm};
          text-align: center;
          display: block;
          color: ${colors.darkGray};
          margin-top: 30px;
        }
      `}</style>
    </Fragment>
  );
};

export { ConfirmationEmailMsg };

export default ConfirmationEmailMsg;
