import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { fontSize } from 'styles/cp';

export interface Props {}

const FullPaymentMsg: React.FC<Props> = () => {
  return (
    <Fragment>
      <span className="payment-full">
        <FormattedMessage id="PAYNOW_PAYMENT_FULL" defaultMessage="Paid" />
      </span>

      <style jsx>{`
        .payment-full {
          font-family: AvenirNextforINTUIT-Demi;
          font-size: ${fontSize.xl};
          background: -webkit-linear-gradient(250.24deg, #00c1bf -20.7%, #53b700 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
        }
      `}</style>
    </Fragment>
  );
};

export { FullPaymentMsg };

export default FullPaymentMsg;
