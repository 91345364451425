import React from 'react';
import { FormattedMessage } from 'react-intl';

import { breakpoints, colors, fontSize } from 'styles/cp';

export interface InvoiceStatusProps {
  idValue: 'INVOICE_SUMMARY_PARTIALLY_PAID' | null;
}

const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ idValue }) => {
  if (!idValue) {
    // no need to render status if status missing
    return <></>;
  }
  return (
    <li className="space-between flex-row">
      <span className="label left">
        <FormattedMessage id="INVOICE_SUMMARY_STATUS" defaultMessage="Status" />
      </span>
      <span className="label label-data p right trunc">
        <FormattedMessage id={idValue} defaultMessage="Partially Paid" />
      </span>

      <style jsx>{`
        .space-between {
          display: flex;
          justify-content: space-between;
        }

        .flex-row {
          flex-direction: row;
          margin: 6px 0px;

          .label {
            font-family: AvenirNextforINTUIT-Regular;
            cursor: text;
            font-size: ${fontSize.xs};
            color: ${colors.gray};
            display: flex;

            &.p {
              font-size: ${fontSize.xxs};
              align-self: center;
              justify-content: center;
              align-items: center;
              height: 16px;
              padding: 0 5px;
              border-radius: 4px;
              background-color: ${colors.gray06};
            }
          }
          .left {
            text-align: left;
          }
          .right {
            font-family: AvenirNextforINTUIT-Medium;
            text-align: right;
          }
          @media (max-width: ${breakpoints.md}) {
            max-width: 400px;
            margin: 5px auto;
          }
          @media (max-width: ${breakpoints.sm}) {
            max-width: 576px;
          }
        }
      `}</style>
    </li>
  );
};

export default InvoiceStatus;
