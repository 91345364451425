import React, { Fragment } from 'react';

import InvoicePreviewWrapper from 'components/Core/Invoice/Preview/Wrapper/InvoicePreviewWrapper';
import Hr from 'components/Shared/Hr/Hr';
import { ErrorInfo } from 'components/Shared/Icons/Icons';
import { fontSize, colors } from 'styles/cp';

export interface Props {
  image?: string;
  title: string | React.ReactElement;
  sections: React.ComponentElement<{ [key: string]: any; key: string }, any>[];
  error?: JSX.Element;
}

const PreviewCard: React.FC<Props> = ({ image, title, sections, error }: Props) => {
  return (
    <Fragment>
      <InvoicePreviewWrapper image={image} title={title}>
        {sections &&
          Array.isArray(sections) &&
          sections.length > 0 &&
          sections.reduce(
            (
              acc: React.ComponentElement<any, any>[],
              section: React.ComponentElement<any, any>,
              idx: number
            ) => {
              if (idx !== 0 && idx < sections.length - 1) {
                acc.push(
                  <Hr
                    key={`separator-${idx}`}
                    marginTop={10}
                    marginBottom={10}
                    borderColor={colors.offWhite}
                    opacity={1}
                  />
                );
              }
              acc.push(
                <div key={`content-${idx}`} className="card-sides">
                  {section}
                </div>
              );
              return acc;
            },
            []
          )}
        {error && (
          <div className="card-sides error flex">
            <div className="icon flex-column">
              <ErrorInfo color={colors.gray02} width={20} height={20} />
            </div>
            <div className="err-msg">{error}</div>
          </div>
        )}
      </InvoicePreviewWrapper>
      <style jsx>{`
        .card-sides {
          padding: 0 0 0 0;
        }
        .err-msg {
          font-size: ${fontSize.xxs};
          padding: 0 0 7px 15px;
        }
        .icon {
          align-self: center;
        }
      `}</style>
    </Fragment>
  );
};

export default PreviewCard;
